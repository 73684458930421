<script setup lang='ts'>
import { getComplaintStatus } from '@/shared/function'

const props = withDefaults(defineProps<{
  code?: number | string
  statusList?: number[]
  statusText?: string
  bgColor?: string
}>(), {
  code: 0,
})
</script>

<template>
  <span class="status flex-shrink-0 leading-1.5em" :class="[`status-${code}`]">{{ getComplaintStatus(props.code) }}</span>
</template>

<style lang='scss' scoped>
.status {
  --uno: rounded px-2 py-1 text-white bg-gray-4 text-2.5 mr-1 text-center;
  vertical-align: 0.15em;
  &-1 {
    --uno: bg-blue-5;
  }
  &-2 {
    background-color: #ff9f1d;
  }
  &-3 {
    background-color: #7d66ec;
  }
  &-4 {
    background-color: #ff7649;
  }
  &-8 {
    background-color: #ec1313;
  }

}
</style>
